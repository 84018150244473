import React, { useRef } from "react"
import styled from "styled-components"
import { graphql, StaticQuery, Link } from "gatsby"

import Chevron from "../../images/svg/chevron.svg"

const Aktuelno = () => {
  const scrollEl = useRef(null)
  const cardWidth = useRef(null)

  const scrollRight = () => {
    scrollEl.current.scrollLeft += cardWidth.current.offsetWidth + 20
  }
  const scrollLeft = () => {
    scrollEl.current.scrollLeft -= cardWidth.current.offsetWidth + 20
  }

  return (
    <StaticQuery
      query={fetchProducts}
      render={data => (
        <Section>
          <h1>AKTUELNO</h1>
          <div className="cards" ref={scrollEl}>
            {data.allSanityProduct.nodes.map(product => {
              let link
              if (product.categories[0].parents.length > 0) {
                link = `/${product.categories[0].parents[0].slug.current}/${product.categories[0].slug.current}/${product.slug.current}`
              } else {
                link = `/${product.categories[0].slug.current}/${product.slug.current}`
              }

              return (
                <Link to={link} key={product.title}>
                  <div className="card" ref={cardWidth}>
                    <h3>{product.title}</h3>
                    <img
                      src={
                        product.defaultProductVariant.images.asset.fluid.srcWebp
                      }
                      alt={product.title}
                    />
                  </div>
                </Link>
              )
            })}
          </div>
          <Arrow
            onClick={scrollLeft}
            style={{ transform: "scale(-1,1)", left: "7.5%" }}
          >
            <Chevron />
          </Arrow>
          <Arrow onClick={scrollRight}>
            <Chevron />
          </Arrow>
        </Section>
      )}
    />
  )
}

export default Aktuelno

const fetchProducts = graphql`
  query {
    allSanityProduct(
      filter: { tags: { eq: "aktuelno" } }
      sort: { fields: _createdAt, order: DESC }
    ) {
      nodes {
        title
        slug {
          current
        }
        defaultProductVariant {
          images {
            asset {
              fluid {
                srcWebp
              }
            }
          }
        }
        categories {
          slug {
            current
          }
          parents {
            slug {
              current
            }
          }
        }
      }
    }
  }
`

const Section = styled.section`
  padding: 60px 0;
  position: relative;
  > h1 {
    font-size: 36px;
    text-align: center;
  }
  .cards {
    box-sizing: border-box;
    display: flex;
    overflow-x: auto;
    margin: 0 auto;
    max-width: 70%;
    scroll-behavior: smooth;
    white-space: nowrap;
    ::-webkit-scrollbar {
      display: none;
    }
    .card {
      box-sizing: border-box;
      margin: 20px 0;
      padding: 0 20px;
      position: relative;
      min-width: 400px;
      h3 {
        background-color: #ffffff;
        border-radius: 0 25px 0 25px;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        padding: 15px;
        position: absolute;
        bottom: 0;
        left: 20px;
        width: 40%;
      }
      img {
        object-fit: cover;
        border-radius: 25px;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .cards {
      max-width: 100%;
      .card {
        min-width: 250px;
        max-width: 100%;
      }
    }
  }
`

const Arrow = styled.button`
  background-color: #fff;
  border: none;
  border-radius: 20px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  position: absolute;
  right: 7.5%;
  bottom: calc(50% - 45px);
  width: 67px;
  :hover {
    opacity: 0.95;
  }
  svg {
    fill: #434343;
    width: 35px;
    height: 35px;
    padding: 10px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`
