import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Chevron from "../../images/svg/chevron.svg"

const Carousel = () => (
  <StaticQuery
    query={backgroundImages}
    render={data => {
      const akcija = data.akcija.childImageSharp.fluid
      return (
        <Background fluid={akcija}>
          <section>
            <h1>AKCIJA</h1>
            <p>Do 30% popusta</p>
            <Link to="#kategorije">
              KATEGORIJE <Chevron />
            </Link>
          </section>
        </Background>
      )
    }}
  />
)

export default Carousel

const backgroundImages = graphql`
  query {
    akcija: file(relativePath: { eq: "akcija.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Background = styled(BackgroundImage)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  section {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 90vh;
    width: 100%;
    h1 {
      border: 10px solid #fff;
      display: inline-block;
      margin: 20px 0;
      font-size: 80px;
      padding: 10px 20px;
    }
    p {
      font-size: 32px;
      margin: 20px 0;
    }
    a {
      background-color: #fff;
      color: #434343;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: bold;
      margin: 20px 0;
      padding: 20px 40px;

      svg {
        fill: #434343;
        margin-left: 15px;
        transition: all 0.3s;
      }
      :hover {
        svg {
          transform: translateX(5px);
        }
      }
    }
  }
`
