import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Add from "../../images/svg/add.svg"
import DropdownMenu from "./dropdownMenu"

const DropdownCard = props => {
  const thumbnails = useStaticQuery(graphql`
    query {
      slikeNaPlatnu: file(
        relativePath: { eq: "thumbnails/slike-na-platnu.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 430, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Card className="card" onClick={toggleOpen}>
      <h3>
        Slike na platnu <Add />
      </h3>
      <Img fadeIn fluid={thumbnails.slikeNaPlatnu.childImageSharp.fluid} />
      <DropdownMenu isOpen={isOpen} />
    </Card>
  )
}

export default DropdownCard

const Card = styled.div`
  border-radius: 25px;
  cursor: pointer;
  display: block;
  margin: 20px;
  position: relative;
  max-width: 400px;
  width: 100%;
  :hover {
    opacity: 0.95;
    svg {
      transform: scale(1.2);
    }
  }
  h3 {
    background-color: #ffffff;
    border-radius: 25px;
    color: #4881d7;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 15px;
    position: absolute;
    bottom: 0;
    left: calc(20% - 30px);
    width: 60%;
    z-index: 2;
  }
  svg {
    fill: #4881d7;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 13px;
    transition: all 0.3s;
  }
`
