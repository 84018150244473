import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Chevron from "../../images/svg/chevron.svg"

const LinkCard = props => (
  <Card to={props.link}>
    <h3>
      {props.text} <Chevron />
    </h3>
    <Img fadeIn fluid={props.img} />
  </Card>
)

export default LinkCard

const Card = styled(Link)`
  border-radius: 25px;
  display: block;
  margin: 20px;
  position: relative;
  max-width: 400px;
  width: 100%;
  :hover {
    svg {
      transform: translateX(3px);
    }
  }
  h3 {
    background-color: #ffffff;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 15px;
    position: absolute;
    bottom: 0;
    left: calc(20% - 30px);
    width: 60%;
    z-index: 2;
  }
  svg {
    fill: #4881d7;
    position: absolute;
    bottom: 2px;
    right: 0;
    padding: 13px;
    transition: all 0.3s;
  }
`
