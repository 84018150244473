import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const DropdownMenu = props => {
  return (
    <Menu isOpen={props.isOpen}>
      <Link to="/slike-na-platnu/jednodjelne-slike">Jednodjelne slike</Link>
      <Link to="/slike-na-platnu/dvodjelne-slike">Dvodjelne slike</Link>
      <Link to="/slike-na-platnu/trodjelne-slike">Trodjelne slike</Link>
      <Link to="/slike-na-platnu/cetverodjelne-slike">Četverodjelne slike</Link>
      <Link to="/slike-na-platnu/petodjelne-slike">Petodjelne slike</Link>
    </Menu>
  )
}

export default DropdownMenu

const Menu = styled.div`
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
  display: ${props => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  bottom: 49px;
  right: calc(50% - 80px);
  z-index: 2;
  a {
    border-radius: 25px;
    padding: 15px 20px;
    :hover {
      background-color: #eee;
    }
    :not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
`
