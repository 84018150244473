import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/index/carousel"
import Categories from "../components/index/categories"
import Aktuelno from "../components/index/aktuelno"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Početna" />
      <Carousel />
      <Categories />
      <Aktuelno />
    </Layout>
  )
}

export default IndexPage
