import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import DropdownCard from "./dropdownCard"
import LinkCard from "./linkCard"

const Categories = () => {
  const thumbnails = useStaticQuery(graphql`
    query {
      tapete: file(relativePath: { eq: "thumbnails/tapete.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 430, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <CategorySection id="kategorije">
      <h1>KATEGORIJE</h1>
      <div className="categories">
        <DropdownCard />
        <LinkCard
          link="/tapete"
          text="Tapete"
          img={thumbnails.tapete.childImageSharp.fluid}
        />
      </div>
    </CategorySection>
  )
}

export default Categories

const CategorySection = styled.section`
  > h1 {
    font-size: 36px;
    text-align: center;
  }
  .categories {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
`
